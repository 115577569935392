export function simpleSearch(keyword, data, key = 'name') {

  keyword = _.lowerCase(keyword);
  return _.filter(data, o => _.lowerCase(o[key]).includes(keyword));

}

export function filterdedElems(keyword, data, key = 'name') {
  keyword = _.lowerCase(keyword);
  return _.filter(data, o => !_.lowerCase(o[key]).includes(keyword));
}

export function accessoriesTypeSearch(keyword, data) {

  let result = [],
    styles = [];

  _.forEach(data, (v, k) => {
    styles = simpleSearch(keyword, v.styles);
    if (Object.keys(styles).length > 0) {
      result.push({
        id: k,
        name: v.name,
        styles: styles
      });
    }
  });

  return result;

}

export function filtratePackages(type, style, grade, data) {

  if (_.toInteger(type) > 0) {

    let options = {
      type: _.toInteger(type)
    };

    if (_.toInteger(style) > 0) {
      options.styles = [{id: _.toInteger(style)}];
    }

    if (_.toInteger(grade) > 0) {
      options.grades = [{id: _.toInteger(grade)}];
    }

    return _.filter(data, options);
  }
  return data;
}

export function getFilterStyles(type, data) {

  let response = {};

  type = _.toInteger(type);

  _.forEach(data, (v) => {
    if (_.toInteger(v.type) === type) {
      _.forEach(v.styles, (s) => {
          response[s.id] = {
              id: s.id,
              name: s.name
          }
      })
    }
  });

  return _.toArray(response);
}

export function getFilterGrades(type, style, data) {

  let response = {};

  type = _.toInteger(type);
  style = _.toInteger(style);

  _.forEach(data, (v) => {
    if (_.toInteger(v.type) === type && _.find(v.styles, {id: style})) {
        _.forEach(v.grades, (g) => {
          let item = _.find(v.item, {
              type: type,
              style_id: style,
              grade_id: _.toInteger(g.id),
          });

          if (!_.isUndefined(item)) {
              response[g.id] = {
                  id: g.id,
                  name: g.name
              }
          }
        })
    }
  });

  return _.toArray(response);
}

export function searchColors(query, items) {

  let result = {};

  _.forEach(items, (v, k) => {
    result[k] = {
      is_show_price_warning: v.is_show_price_warning,
      colours: simpleSearch(query, v.colours, 'colour_name')
    };
  });

  return result;

}
