<template>
  <div :class="{'modal modal_wrap': modalView}">
    <div :class="{'modal_popup modal_content': modalView}">
      <div>
          <breadcrumbs v-if="!modalView && !isEdit"></breadcrumbs>
          <h2 class="titlePage_subtitle titlePage">SELECT INSERT</h2>

            <div class="row">
              <div class="col-12 col-md-6">
                <form action="" class="form" v-on:submit.prevent>
                  <label class="form__label">
                    <input type="text" class="form__input"
                           placeholder="Type to Search"
                           name="search color"
                           v-model="searchInput"
                    >
                  </label>
                </form>
              </div>
            </div>
      </div>
      <div class="colour-wrapper row" v-if="this.colour">
        <div class="col-12 col-md-6 col-lg-4">
          <h4>Colour</h4>
          <div class="row align-middle" v-if="this.colour">
            <div class="col-6 align-middle">
              <p class="text_default">
                {{this.colour.name}} <span v-if="this.colour.is_same_as_frame"> (same as frame)</span> </p><br>
            </div>
            <div class="col-6 align-middle"
                 @click="openColourSelection()"
            >
              <div class="colours colour_box colours_small">
              <img class="box__img" :src="this.colour.image"
                   alt="color.colour_name"
                   v-if="this.colour.image">
              <p v-else-if="this.colour.hexcode">
                <svg-icon name="palette" class="large_icon box__img"
                                   :style="{color: this.colour.hexcode}"/>
              </p>
              <p v-if="!this.colour.image && !this.colour.hexcode">
                <svg-icon name="palette" class="large_icon box__img"/>
              </p>
              </div>
            </div>
            <div class="col-12">
              <br>
            </div>
          </div>

        </div>
      </div>
      <div :class="{'shift-bottom': this.colour}">
        <section class="item-types">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 col-12 text-center"
                 v-for="item in getNonDefaultInserts"
                 :key="item.id"
                 >
              <a role="button" class="item-card-link" @click="selectOption(item)" :class="{'disabled': checkDisabled(item)}">
                <div class="text-center">
                  <v-lazy-image-plugin :src="item.image" :alt="item.name" class="img-preview"
                                       :class="{active: !checkDisabled(item)}"/>
                </div>
                <div :class="getDisabledClass(item)">Width: {{ item.min_width }} - {{ item.max_width }} mm</div>
                <div :class="getDisabledClass(item)">Drop: {{ item.min_drop }} - {{ item.max_drop }} mm</div>
                <label class="form__label form__label_row">
                  <input type="radio" hidden="hidden"
                         class="form__radio"
                         name="mesh"
                         :value="item.id"
                         @change="selectOption(item)"
                         v-model="checked"
                         :disabled="checkDisabled(item)"
                  >
                  <span class="form__radio_icon"></span>
                  <span class="form__labelTitle">{{ item.name }}</span>
                </label>
              </a>
            </div>
          </div>
          <template v-if="actionLinksPortalTarget">
            <portal :to="actionLinksPortalTarget">
              <button type="button" class="btn btn_transparent" :disabled="checked === ''" v-if="!isUpdate"
                      @click.prevent="next"><svg-icon name="solid/step-forward" /> next
              </button>
              <button type="button" class="btn btn_transparent" :disabled="checked === ''" v-else @click.prevent="next">done
              </button>
            </portal>
          </template>

          <template v-else>
            <button type="button" class="btn"
                    v-bind:class="{ 'fixed btn_transparent' : selected, 'btn_default' : !selected }"
                    :disabled="checked === ''" v-if="!isUpdate"
                    @click.prevent="next"><svg-icon name="solid/step-forward" />
            </button>
            <button type="button" class="btn btn_default" :disabled="checked === ''" v-else @click.prevent="next">done
            </button>
          </template>
        </section>
      </div>

    </div>
    <transition name="fade" mode="out-in">
      <select-colors v-show="selectColours" @onSelect="setColour"></select-colors>
    </transition>
  </div>
</template>

<script>
  import breadcrumbs from "./../../parts/breadcrumbs";
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import {searchColors, simpleSearch} from "../../../helpers/search";
  import VLazyImagePlugin from "v-lazy-image";
  import {defineAsyncComponent} from "vue";

  const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));

  export default {
    name: "Inserts",
    components: {
        breadcrumbs,
        SelectColors,
        VLazyImagePlugin},
    data() {
      return {
        checked: '',
          settings: null,
        selected: false,
        selection: [],
        searchInput: '',
          colour_group: null,
          colour: null,
        enable: false,
          modalView: false
      }
    },
      props: ['options', 'actionLinksPortalTarget'],
    methods: {
    ...mapMutations('options', ['setTypeOrigin', 'setType', 'setInsertIds']),
//    ...mapActions('options', ['getInsertColours']),


        setColour(c) {
            // let c = this.$store.getters['optionColours/selectedColour'];

            this.colour = c.colour;

            _.forEach(this.selection.groups.default.choices, (i, k) => {
                if (i.default == true) {
                    i.colour = this.colour;
                }
            });

            this.$store.commit('options/updateColour', {option: 'INS', colour: c.colour});
        },

        openColourSelection() {
            if (this.colour_group)
                this.$store.commit('optionColours/colourGroup', this.colour_group);

            this.$store.commit('optionColours/openModal', true);
            this.$store.commit('optionColours/getColors');
        },



      selectOption(item) {
          const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);
          if(this.isEdit) {
              const rollbackHandler = () => {
                  this.$store.commit('orderPosition/setOption', {
                      option: 'INS',
                      selection: backup
                  });
              }
              this.$store.dispatch('orderPosition/trackChanges', rollbackHandler)
          }
          this.selected = true;
          this.checked = item.id;
        _.forEach(this.selection.groups.default.choices, (i, k) => {
            i.default = item.name == i.name;
            if (i.default == true) {
                this.colour = i.colour;
                this.colour_group = i.colour_group;
            }
        });


        this.$store.commit('orderPosition/setOption', {option: 'INS', selection: this.selection});
//        this.getInsertColours();
      },

      next() {
          if(this.isEdit) {
              // const settings = _.cloneDeep(this.settings)
              const backup = _.cloneDeep(this.$store.getters['orderPosition/getCurrentOptionSettings']);

              const rollbackHandler = () => {
                  this.$store.commit('orderPosition/setOption', {option: 'INS', selection: backup});
              }
              this.$store.dispatch('orderPosition/guessNextStep', rollbackHandler);
          }else {
              this.$store.commit('orderPosition/optionNext', 'INS');
          }

      },

      checkDefault() {
        _.forEach(this.selection.groups.default.choices, (o) => {
          if (o.default == true) {
              this.checked = o.id;
            this.selectOption(o);
          }
        });

      },
      getDisabledClass(elem){
          let cl = 'text_small';
          if (this.checkDisabled(elem))
              cl = 'text_small red';
          return cl;
      },
      checkDisabled(elem) {
        let width = parseFloat(this.pos.wdt),
          drop = parseFloat(this.pos.htl),
          minWidth = this.getComponentLenthByProperty(elem, 'min_width'),
          maxWidth = this.getComponentLenthByProperty(elem, 'max_width', 'max'),
          minDrop = this.getComponentLenthByProperty(elem, 'min_drop'),
          maxDrop = this.getComponentLenthByProperty(elem, 'max_drop', 'max');

        if ((minWidth <= width && width <= maxWidth) && (minDrop <= drop && drop <= maxDrop)) {
          this.enable = true;
          return false;
        }

        return true
      },
      getComponentLenthByProperty(insert, property, aggregation = 'min') {
        if (!_.isUndefined(insert[property])) {
          return _.toInteger(insert[property])
        }

        if (_.isUndefined(insert.components)) {
          return 0
        }

        return _[aggregation](_.map(insert.components, property));
      },

      closePopup() {
        this.$store.commit('options/closeAvailable');
      },


    },
    computed: {
        ...mapGetters({
            pos: 'orderPosition/orderPosition',
            selectColours: 'optionColours/openModal',
            isEdit: 'orderPosition/isCompleted',
        }),

        inserts() {
            return simpleSearch(this.searchInput, this.selection.groups.default.choices, 'name')
        },
      getNonDefaultInserts(){
        return _.filter(this.inserts,(item)=> {
          return !item.is_default
        })
      },

      ...mapState('orderItem', ['isUpdate']),
      ...mapState('orderPosition', ['movingBackFrom']),
    },

    created() {
        this.selection = this.$store.getters['orderPosition/getCurrentOptionSettings'];
        this.settings = _.cloneDeep(this.selection)
      setTimeout(() => {
        this.checkDefault();
      }, 500)
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .btn {
    &:disabled {
      border-color: $gray;
      color: $gray;
      cursor: not-allowed;
    }
  }

  .item-card-link {
    display: block;
    cursor: pointer;

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    img {
      transition: all .3s linear;
    }
    &:hover {
      img {
        filter: grayscale(50%);
      }
    }
  }
  .orderItem__listItems {
    margin-bottom: 20px;
    cursor: pointer;
    &_disable {
      cursor: auto;
      opacity: 0.6;
    }
  }

  .form {

    &__label {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }

    &__labelTitle {
      color: $navy;
    }

    &__radio {
      &:disabled {
        ~ .form__labelTitle {
          cursor: default;
          opacity: 0.5;
          &:hover {
            color: $navy;
          }
        }
      }
    }
  }

  .text_white {
    position: absolute;
    bottom: 3rem;
    left: auto;
    right: 2rem;
    display: inline-block;
    width: auto;
  }

  .modal__close {
    color: $green;
  }

  .img-preview{
    height: 300px;
    width: auto;
    max-width: 100%;
    padding: 10px;
  }

  .active{
    background-color: #51B592;
  }

  .red{
    color: darkred;
  }

  .fixed{
    position: absolute;
    bottom: 32px;
    left: 130px;
  }


  section.item-types{
    padding-bottom: 50px;
  }

  .colour-wrapper{
    position: absolute;
    background-color: white;
    bottom: 0;
    z-index: 1;
    width: calc(100% - 5rem);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .shift-bottom{
    padding-bottom: 70px;
  }

@media (max-width: 768px){
  .fixed{
    bottom: 28px;
    left: 110px;
  }

}

</style>
